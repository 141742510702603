@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply leading-[normal] m-0;
}

html, body {
  overscroll-behavior: none;
  height: 100%;
  overflow: hidden;
}

@media (orientation: landscape) and (max-width: 1194px) {
  .chat-field {
    height: calc(100vh - 50px); /* Adjust height to ensure it fits within the viewport */
  }
  .other-elements {
    /* Add necessary styles to ensure other elements are not cropped */
  }
}
